<template>
  <div
    class="similar-candidate-card"
    @click="redirectToCandidateProfile"
  >
    <img
      class="similar-candidate-card__candidate-avatar"
      :src="candidate.avatarUrl"
      data-test="avatar"
    >
    <div class="similar-candidate-card__headline">
      <div class="similar-candidate-card__name" data-test="name">
        {{ candidate.name }}
      </div>
      <div
        class="similar-candidate-card__experience-title"
        data-test="last-experience-title"
      >
        {{ candidate.lastExperienceTitle }}
      </div>
    </div>
    <div class="similar-candidate-card__candidate-info">
      <div class="similar-candidate-card__info-group">
        <ev-icon
          name="money-bill"
          size="small"
          class="similar-candidate-card__icon"
        />
        <span class="similar-candidate-card__info" data-test="salary">
          {{ $t(langPath).salaryExpectation }}
          {{ candidateSalary }}
        </span>
      </div>

      <div class="similar-candidate-card__info-group">
        <ev-icon
          name="user-location"
          size="small"
          class="similar-candidate-card__icon"
        />
        <span class="info" data-test="location">{{ candidate.location }}</span>
      </div>
      <div class="similar-candidate-card__info-group">
        <ev-icon
          name="suitcase"
          size="small"
          class="similar-candidate-card__icon"
        />
        <span class="similar-candidate-card__info" data-test="main-skills">
          {{ candidateMainSkills }}
        </span>
      </div>
      <div class="similar-candidate-card__info-group">
        <ev-icon
          name="user-check"
          size="small"
          class="similar-candidate-card__icon"
        />
        <span class="similar-candidate-card__info" data-test="experience-time">
          {{ candidateExperienceTime }}
        </span>
      </div>
    </div>
    <ev-button
      variant="tertiary"
      class="similar-candidate-card__profile-button"
      data-test="open-full-profile"
      @click="redirectToCandidateProfile"
    >
      {{ $t(`${langPath}.viewProfileText`) }}
    </ev-button>
  </div>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import toSentence from '@/mixins/to-sentence'

export default {
  name: 'SimilarCandidateCard',
  components: {
    EvButton,
    EvIcon
  },
  mixins: [toSentence],
  props: {
    candidate: {
      type: Object,
      required: true
    },
    source: {
      type: String,
      default: null,
      required: false
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    skillsSeparator () {
      return this.$t(`${this.langPath}.skillsSeparator`)
    },
    candidateMainSkills () {
      return this.toSentence(this.candidate.mainSkills, this.skillsSeparator)
    },
    candidateSalary () {
      return this.$options.filters.currency(this.candidate.minimumSalary)
    },
    candidateExperienceTime () {
      return this.$t(`${this.langPath}.experienceTime.${this.candidate.experienceTime}`)
    }
  },
  methods: {
    redirectToCandidateProfile () {
      const query = {
        name: 'CandidateProfile',
        params: {
          id: this.candidate.id
        },
        query: {
          source: this.source || 'InviteConfirmation',
          sourceId: this.$route.params.inviteId
        }
      }
      const candidateProfileUrl = this.$router.resolve(query).href
      window.open(candidateProfileUrl, '_blank')
    }
  }
}
</script>

<style lang="scss">
.similar-candidate-card {
  align-items: center;
  background-color: var(--bg-neutral-blank);
  border-radius: $app-border-radius;
  box-shadow: $shadow-8;
  display: flex;
  flex-direction: column;
  padding: 12*$base;

  &:hover {
    cursor: pointer;
  }

  &__headline {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-bottom: 3*$base;
  }

  &__name {
    color: var(--tx-neutral-base);
    font-weight: bold;
    margin-top: 2*$base;
  }

  &__experience-title {
    color: var(--tx-neutral-light-01);
    font-size: 3.5*$base;
    margin-top: 2*$base;
  }

  &__candidate-info {
    display: flex;
    flex-direction: column;
    font-size: 3.5*$base;
    width: 100%;
  }

  &__info-group {
    display: flex;
    padding: 2*$base 0;
  }

  &__icon {
    margin: 0 2*$base;
  }

  &__info {
    line-height: 4*$base;
    margin: 6px 0;
    word-break: break-all;

    &--clickable {
      @extend %bold;

      color: var(--tx-blue);

      &:active {
        transform: scale(0.98);
      }
    }
  }

  &__profile-button {
    margin-top: 4*$base;
    width: 75%;
  }

  &__candidate-avatar {
    border-radius: 50%;
    cursor: pointer;
    max-height: 25*$base;
    max-width: 25*$base;
  }
}
</style>
