export default {
  methods: {
    formatNumbersWithSamePrecision (firstNumber, secondNumber) {
      const firstCharsCount = firstNumber.toString().split('').length
      const secondCharsCount = secondNumber.toString().split('').length

      const firstDifference = Math.max(secondCharsCount - firstCharsCount, 0)
      const secondDifference = Math.max(firstCharsCount - secondCharsCount, 0)

      const firstPadding = '0'.repeat(firstDifference)
      const secondPadding = '0'.repeat(secondDifference)

      return {
        first: `${firstPadding}${firstNumber}`,
        second: `${secondPadding}${secondNumber}`
      }
    }
  }
}
