export default {
  methods: {
    toSentence (strings, separator = '') {
      if (!Array.isArray(strings)) return strings
      if (strings.length === 0) return ''
      if (strings.length === 1) return strings[0]

      const jointWithComma = strings.slice(0, -1)
      const lastElement = strings.slice(-1)

      return `${jointWithComma.join(', ')} ${separator || '-'} ${lastElement}`
    }
  }
}
