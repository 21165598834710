<template>
  <app-loader
    v-if="isFetchingInviteSummary"
    :message="$t(langPath).fetchingSummary"
    data-test="loader"
    class="invite-summary__loading"
  />
  <div class="invite-summary" v-else>
    <app-grid data-test="invite-summary">
      <app-grid-col :xl="4">
        <div class="invite-summary__images-container">
          <div class="invite-summary__icons">
            <ev-icon
              class="invite-summary__mail"
              name="envelope"
              color="success"
              size="medium"
            />
            <ev-icon
              class="invite-summary__arrow"
              name="arrow-right"
              color="success"
              size="medium"
            />
          </div>
          <div
            class="invite-summary__avatar"
            data-test="avatar"
            @click="redirectToInvite"
          >
            <img
              v-tooltip="$t(langPath).invitedCandidateTooltip"
              class="invite-summary__candidate-avatar"
              data-test="avatar-image"
              :src="invitedCandidate.avatarUrl"
            >
            <ev-icon
              class="invite-summary__check"
              name="check-circle"
              color="success"
              size="medium"
            />
          </div>
        </div>
        <h3 class="invite-summary__sent-title">
          {{ $t(`${langPath}.invite.sent`) }}
        </h3>
        <div class="invite-summary__next-steps">
          <p>
            <strong
              class="invite-summary__candidate-name"
              data-test="candidate-name"
            >
              {{ inviteSummary.invitedCandidate.name }}
            </strong>
            {{ $t(`${langPath}.invite.sentTo`) }}
          </p>
          <p>{{ $t(`${langPath}.invite.waitText`) }}</p>
        </div>
      </app-grid-col>
      <app-grid-col :xl="5">
        <h3 class="invite-summary__info-header">
          {{ $t(`${langPath}.invite.header`) }}
        </h3>
        <dl class="invite-summary__invite-details">
          <div class="invite-summary__invite-data">
            <dt class="invite-summary__invite-label">
              {{ $t(`${langPath}.details.career`) }}
            </dt>
            <dd class="invite-summary__invite-value" data-test="invite-title">
              {{ inviteSummary.title }}
            </dd>
          </div>
          <div class="invite-summary__invite-data">
            <dt class="invite-summary__invite-label">
              {{ $t(`${langPath}.details.location`) }}
            </dt>
            <dd
              class="invite-summary__invite-value"
              data-test="candidate-location"
            >
              {{ inviteSummary.invitedCandidate.location }}
            </dd>
          </div>
          <div class="invite-summary__invite-data">
            <dt class="invite-summary__invite-label">
              {{ $t(`${langPath}.details.salaryRange`) }}
            </dt>
            <dd class="invite-summary__invite-value" data-test="salary-range">
              {{ inviteSalaryRange }}
            </dd>
          </div>
          <div class="invite-summary__invite-data">
            <dt class="invite-summary__invite-label">
              {{ $t(`${langPath}.details.skills.label`) }}
            </dt>
            <dd
              class="invite-summary__invite-value"
              data-test="candidate-skills"
            >
              {{ invitedCandidateMainSkills }}
            </dd>
          </div>
          <div class="invite-summary__invite-data">
            <dt class="invite-summary__invite-label">
              {{ $t(`${langPath}.details.salaryType`) }}
            </dt>
            <dd class="invite-summary__invite-value" data-test="salary-type">
              {{ inviteSummary.salaryType }}
            </dd>
          </div>
          <div class="invite-summary__invite-data">
            <dt class="invite-summary__invite-label">
              {{ $t(`${langPath}.details.experienceTime.label`) }}
            </dt>
            <dd
              class="invite-summary__invite-value"
              data-test="candidate-experience-time"
            >
              {{ invitedCandidateExperienceTime }}
            </dd>
          </div>
        </dl>
      </app-grid-col>
      <app-grid-col :xl="3" class="invite-summary__counting-container">
        <h3 class="invite-summary__counting-title">
          {{ $t(`${langPath}.counting.title`) }}
        </h3>
        <div class="invite-summary__counting-box">
          <span class="invite-summary__counting" data-test="invites-count">
            {{ countingData.invites }}
          </span>
          <span class="invite-summary__legend">
            {{ $t(`${langPath}.counting.invites`) }}
          </span>
        </div>
        <div class="invite-summary__counting-box">
          <span class="invite-summary__counting" data-test="interviews-count">
            {{ countingData.interviews }}
          </span>
          <span class="invite-summary__legend">
            {{ $t(`${langPath}.counting.interviews`) }}
          </span>
        </div>
      </app-grid-col>
    </app-grid>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { INVITES } from '@/store/namespaces'
import { AppLoader } from '@revelotech/everest'
import EvIcon from '@revelotech/everestV2/EvIcon'
import toSentence from '@/mixins/to-sentence'
import formatNumbersWithSamePrecision from '@/mixins/format-with-same-precision'

const { mapActions, mapState } = createNamespacedHelpers(INVITES)

export default {
  name: 'InviteSummary',
  components: {
    AppLoader,
    EvIcon
  },
  mixins: [toSentence, formatNumbersWithSamePrecision],
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...mapState(['inviteSummary', 'isFetchingInviteSummary']),
    invitedCandidate () {
      return this.inviteSummary.invitedCandidate
    },
    countingData () {
      const paddedNumbers = this.formatNumbersWithSamePrecision(
        this.inviteSummary.counting.invitesCount,
        this.inviteSummary.counting.interviewsCount
      )

      return { invites: paddedNumbers.first, interviews: paddedNumbers.second }
    },
    invitedCandidateExperienceTime () {
      const experienceTime = this.invitedCandidate.experienceTime
      const experiencePrefix = 'details.experienceTime.values'
      return this.$t(`${this.langPath}.${experiencePrefix}.${experienceTime}`)
    },
    invitedCandidateMainSkills () {
      const jointChar = this.$t(`${this.langPath}.details.skills.jointChar`)
      return this.toSentence(this.invitedCandidate.mainSkills, jointChar)
    },
    inviteSalaryRange () {
      const salaryRange = this.inviteSummary.salaryRange
      const salariesFormatted = {
        minimum: this.$options.filters.currency(salaryRange.minimum),
        maximum: this.$options.filters.currency(salaryRange.maximum)
      }
      return `${salariesFormatted.minimum} - ${salariesFormatted.maximum}`
    }
  },
  created () {
    this.getInviteSummary(this.$route.params.inviteId)
  },
  methods: {
    ...mapActions(['getInviteSummary']),
    redirectToInvite () {
      this.$router.push({
        name: 'EmployersInvite',
        params: { inviteId: this.$route.params.inviteId }
      })
    }
  }
}
</script>

<style lang="scss">
.invite-summary {
  background-color: var(--bg-neutral-blank);
  margin-top: 17*$base;
  padding-bottom: 12*$base;
  padding-top: 12*$base;

  &__loading {
    background-color: var(--bg-neutral-light-04);
    margin-top: 14*$base;
  }

  &__candidate-avatar {
    border-radius: 50%;
    max-height: 25*$base;
    max-width: 25*$base;
  }

  @media (min-width: $container-lg-max-width) {
    &__images-container {
      display: flex;
      flex-flow: row nowrap;
    }

    &__arrow {
      padding: 0 10*$base;
    }

    &__icons {
      align-self: center;
      max-height: 15*$base;
    }

    &__avatar {
      cursor: pointer;
      display: flex;
      flex-flow: column;
      flex-grow: 3;
    }

    &__check {
      bottom: 4*$base;
      left: 26px;
      position: relative;
    }
  }

  @media (min-width: $container-xl-max-width) {
    &__check {
      right: 15*$base;
    }
  }

  @media (max-width: $container-lg-max-width) {
    &__images-container {
      align-content: stretch;
      align-items: stretch;
      display: flex;
      flex-flow: row wrap;
    }

    &__next-steps,
    &__sent-title {
      text-align: center;
    }

    &__icons,
    &__avatar {
      flex-grow: 1;
    }

    &__icons {
      display: flex;
      justify-content: flex-end;
    }

    &__avatar {
      align-items: flex-start;
      display: flex;
      flex-flow: column;
    }

    &__check {
      bottom: 4*$base;
      left: 9*$base;
      position: relative;
    }
  }

  &__next-steps {
    color: var(--tx-neutral-light-01);
    line-height: 5*$base;

    p {
      font-size: 3.5*$base;
      line-height: 5*$base;
    }
  }

  &__sent-title {
    @extend %h4;

    color: var(--tx-success);
    margin-bottom: 3*$base;
  }

  &__info-header {
    color: var(--tx-neutral-light-01);
    margin-bottom: 3*$base;
  }

  &__counting-container {
    align-content: center;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: $container-sm-max-width) {
    &__counting-container {
      border-left: 0.25*$base solid var(--b-neutral-light-02);
      padding-left: 10*$base;
      padding-right: 10*$base;
    }

    &__counting-title {
      margin-bottom: 10*$base;
    }
  }

  &__counting-title {
    color: var(--tx-neutral-light-01);
    margin-bottom: 4*$base;
  }

  &__counting-box {
    background: var(--bg-neutral-light-04);
    border-radius: $app-border-radius;
    color: var(--tx-blue);
    margin-bottom: $base;
    padding: 6*$base;
  }

  &__legend {
    border-left: 0.25*$base solid var(--b-neutral-light-02);
    font-weight: bold;
    padding-left: 4*$base;
    word-break: break-all;
  }

  &__counting {
    font-size: 6*$base;
    font-weight: bold;
    padding-right: 4*$base;
  }

  &__invite-details {
    display: flex;
    flex-flow: row wrap;
  }

  &__invite-data {
    flex-grow: 1;
    margin-bottom: 3*$base;
    width: 50%;
  }

  &__invite-label {
    color: var(--tx-neutral-light-01);
    font-size: 2.5*$base;
    text-transform: uppercase;
  }

  &__invite-value {
    color: var(--tx-neutral-base);
    font-size: 3.5*$base;
    font-weight: bold;
  }
}
</style>
