<template>
  <div class="invite-sent">
    <invite-summary />
    <similar-candidates />
    <app-grid>
      <app-grid-col :md="12">
        <ev-link
          class="invite-sent__talent-feed-button"
          variant="tertiary"
          data-test="talent-feed-button"
          :a-href="companiesTalentFeedUrl"
        >
          {{ $t(`${langPath}.talentFeedButtonText`) }}
        </ev-link>
      </app-grid-col>
    </app-grid>
  </div>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'
import InviteSummary from './components/InviteSummary'
import SimilarCandidates from './components/SimilarCandidates'
import { companiesUrl } from '@/helpers/constants/external-urls'
import { createNamespacedHelpers } from 'vuex'
import { INVITES } from '@/store/namespaces'

const { mapState } = createNamespacedHelpers(INVITES)

export default {
  name: 'InviteSent',
  components: {
    EvLink,
    InviteSummary,
    SimilarCandidates
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...mapState(['inviteSummary']),
    companiesTalentFeedUrl () {
      if (this.inviteSummary.searchPageHref === undefined) {
        return `${companiesUrl}/employers/candidates`
      }
      return companiesUrl + this.inviteSummary.searchPageHref
    }
  }
}
</script>

<style lang="scss">
.invite-sent {
  background-color: var(--bg-neutral-light-04);

  &__talent-feed-button {
    margin: 10*$base 0;
    padding: 0;
  }
}
</style>
