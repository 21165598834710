<template>
  <app-loader
    v-if="isFetchingSimilarCandidates"
    :message="$t(langPath).fetchingSimilarCandidates"
  />
  <div class="similar-candidates" v-else>
    <app-grid v-if="errorFetchingSimilarCandidates">
      <app-grid-col :xl="12" class="similar-candidates__error-message">
        <p class="sad">(◕︿◕✿)</p>
        <p>{{ $t(`${langPath}.error.description`) }}</p>
        <ev-button
          variant="tertiary"
          data-test="retry-similar-candidates"
          @click="getSimilarCandidates(inviteId)"
        >
          {{ $t(`${langPath}.error.callToAction`) }}
        </ev-button>
      </app-grid-col>
    </app-grid>
    <app-grid v-else data-test="similar-candidates">
      <app-grid-col :md="12">
        <h3 class="similar-candidates__title">
          {{ $t(langPath).title }}
        </h3>
        <p class="similar-candidates__details">
          <span>{{ $t(langPath).description }}</span>
          <strong>{{ $t(langPath).highlight }}</strong>
        </p>
      </app-grid-col>
      <app-grid-col
        :xl="4"
        v-for="similarCandidate in similarCandidates"
        :key="similarCandidate.id"
      >
        <similar-candidate-card
          :data-test="`candidate-id-${similarCandidate.id}`"
          :candidate="similarCandidate"
        />
      </app-grid-col>
    </app-grid>
  </div>
</template>

<script>
import { AppLoader } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'
import { createNamespacedHelpers } from 'vuex'
import { INVITES } from '@/store/namespaces'
import SimilarCandidateCard from './SimilarCandidates/SimilarCandidateCard'

const { mapActions, mapState } = createNamespacedHelpers(INVITES)

export default {
  name: 'SimilarCandidates',
  components: {
    AppLoader,
    EvButton,
    SimilarCandidateCard
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...mapState([
      'similarCandidates',
      'isFetchingSimilarCandidates',
      'errorFetchingSimilarCandidates'
    ]),
    inviteId () {
      return this.$route.params.inviteId
    }
  },
  async created () {
    this.getSimilarCandidates(this.inviteId)
  },
  methods: {
    ...mapActions(['getSimilarCandidates'])
  }
}
</script>

<style lang="scss">
.similar-candidates {
  padding-top: 11*$base;

  &__title {
    @extend %h3;

    font-size: 5.5*$base;
  }

  &__details {
    font-size: 3.5*$base;

    strong {
      margin-left: $base;
    }
  }

  &__error-message {
    * {
      margin-bottom: 2*$base;
    }

    .sad {
      font-size: 4*$base;
    }
  }
}
</style>
